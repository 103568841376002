<script setup lang="ts">
import { ALL_LOB } from '~/constants'
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useWorkspace } from '~/stores/workspace'

defineProps<{
  admin?: boolean
  mini?: boolean
  navOpen?: boolean
}>()
defineEmits(['nav-open'])

const tenantConfig = useAppConfig().tenant
const appConfig = useAppConfiguration()
const workspace = useWorkspace()

const { getPathWithParams, clearUrlParams } = useUrlParamsState('history')
</script>

<template>
  <div class="flex h-full flex-col">
    <div>
      <div class="sticky top-0 z-10 px-3">
        <div
          v-if="workspace.underMaintenance"
          class="bg-primary-light border-warning mb-6 rounded-lg border border-dashed p-4"
        >
          <i
            v-if="mini"
            class="i-ph-hard-hat text-warning-dark -ml-1 block h-6 w-6"
          />
          <h2 v-else class="text-warning-dark">Under Maintenance</h2>
        </div>
        <SelectConnector
          class="bg-primary mb-4 rounded-b-lg"
          :mini="mini && !navOpen"
          primary
          data-testid="selectConnector"
          @click="$emit('nav-open')"
        />
      </div>
      <ul class="nav-ul">
        <template v-if="workspace.connectorId === ALL_LOB">
          <li class="w-full">
            <AppNavDrawerItem
              :to="getPathWithParams('/dashboard', ['start_date', 'end_date'])"
              data-analytics="nav-dashboard"
              @click="clearUrlParams(['start_date', 'end_date'])"
            >
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-chart-bar': isActive,
                    'i-ph-chart-bar-light': !isActive,
                  }"
                />
              </template>
              Dashboard
            </AppNavDrawerItem>
          </li>
        </template>
        <template v-else>
          <li class="w-full">
            <AppNavDrawerItem
              :to="getPathWithParams('/')"
              data-analytics="nav-dashboard"
              @click="clearUrlParams()"
            >
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-chart-bar': isActive,
                    'i-ph-chart-bar-light': !isActive,
                  }"
                />
              </template>
              Dashboard
            </AppNavDrawerItem>
          </li>
          <li class="w-full">
            <AppNavDrawerItem
              :to="getPathWithParams('/policies')"
              data-analytics="nav-policies"
              @click="clearUrlParams()"
            >
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-books': isActive,
                    'i-ph-books-light': !isActive,
                  }"
                />
              </template>
              Policies
            </AppNavDrawerItem>
          </li>
          <li class="w-full">
            <AppNavDrawerItem
              :to="getPathWithParams('/claims')"
              data-analytics="nav-claims"
              @click="clearUrlParams()"
            >
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-file-text': isActive,
                    'i-ph-file-text-light': !isActive,
                  }"
                />
              </template>
              Claims
            </AppNavDrawerItem>
          </li>
          <li class="w-full">
            <AppNavDrawerItem
              :to="getPathWithParams('/exclusions')"
              data-analytics="nav-exclusions"
              @click="clearUrlParams()"
            >
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-file-x': isActive,
                    'i-ph-file-x-light': !isActive,
                  }"
                />
              </template>
              Exclusions
            </AppNavDrawerItem>
          </li>
          <li class="w-full">
            <AppNavDrawerItem
              :to="getPathWithParams('/changelog')"
              data-analytics="nav-changelog"
              @click="clearUrlParams()"
            >
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-clock-counter-clockwise': isActive,
                    'i-ph-clock-counter-clockwise-light': !isActive,
                  }"
                />
              </template>
              Changelog
            </AppNavDrawerItem>
          </li>
          <li v-if="workspace.inquiriesBaseUrl" class="w-full">
            <AppNavDrawerItem
              :to="workspace.inquiriesBaseUrl"
              target="_blank"
              data-analytics="nav-inquiries"
            >
              <template #icon>
                <i class="i-ph-list-magnifying-glass inline-block h-full w-6" />
              </template>
              <span class="flex items-center justify-between space-x-2">
                Provider Inquiries
                <i
                  class="i-ph-arrow-square-out -mt-[2px] ml-2 inline-block h-4 w-4"
                />
              </span>
            </AppNavDrawerItem>
          </li>
        </template>
      </ul>
    </div>

    <div class="flex flex-1 flex-col pb-4">
      <AuthPermissionsGuard
        :permissions="[PERMISSIONS.readSystem]"
        :workspace-permissions="[
          WORKSPACE_PERMISSION.writeWorkspace,
          WORKSPACE_PERMISSION.readSystem,
          WORKSPACE_PERMISSION.writeSystem,
        ]"
      >
        <ul class="nav-ul mt-4">
          <AuthPermissionsGuard
            :workspace-permissions="[
              WORKSPACE_PERMISSION.readSystem,
              WORKSPACE_PERMISSION.writeSystem,
            ]"
          >
            <li class="w-full">
              <AppNavDrawerItem
                to="/account/api-clients"
                data-analytics="nav-api-clients"
              >
                <template #icon="{ isActive }">
                  <i
                    class="inline-block h-full w-6"
                    :class="{
                      'i-ph-brackets-curly': isActive,
                      'i-ph-brackets-curly-light': !isActive,
                    }"
                  />
                </template>
                API clients
              </AppNavDrawerItem>
            </li>
          </AuthPermissionsGuard>

          <AuthPermissionsGuard
            :permissions="[PERMISSIONS.readUsersAll]"
            :workspace-permissions="[WORKSPACE_PERMISSION.writeWorkspace]"
          >
            <li class="w-full">
              <AppNavDrawerItem to="/account/users" data-analytics="nav-users">
                <template #icon="{ isActive }">
                  <i
                    class="inline-block h-full w-6"
                    :class="{
                      'i-ph-users': isActive,
                      'i-ph-users-light': !isActive,
                    }"
                  />
                </template>
                Users
              </AppNavDrawerItem>
            </li>
          </AuthPermissionsGuard>
        </ul>
      </AuthPermissionsGuard>

      <AuthPermissionsGuard
        v-if="!tenantConfig.hideAdminNavLinks"
        :permissions="[PERMISSIONS.readWorkspacesAll, PERMISSIONS.readUsersAll]"
      >
        <ul class="nav-ul">
          <li class="mt-20 h-px w-full bg-neutral-200"></li>
          <li class="w-full">
            <AppNavDrawerItem to="/admin" data-analytics="nav-workspaces">
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-squares-four': isActive,
                    'i-ph-squares-four-light': !isActive,
                  }"
                />
              </template>
              Workspaces
            </AppNavDrawerItem>
          </li>
          <li class="w-full">
            <AppNavDrawerItem to="/admin/users" data-analytics="nav-all-users">
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-user-list': isActive,
                    'i-ph-user-list-light': !isActive,
                  }"
                />
              </template>
              All users
            </AppNavDrawerItem>
          </li>
        </ul>
      </AuthPermissionsGuard>
    </div>
    <ul v-if="appConfig.feature_zendesk_link" class="nav-ul sticky bottom-0">
      <li class="bg-primary w-full">
        <AppNavDrawerItem
          to="https://rialtic.zendesk.com/hc/en-us"
          target="_blank"
          data-analytics="nav-product-guide"
        >
          <template #icon="{ isActive }">
            <i
              class="inline-block h-full w-6"
              :class="{
                'i-ph-notebook': isActive,
                'i-ph-notebook-light': !isActive,
              }"
            />
          </template>
          <span class="flex items-center justify-between space-x-2">
            Product Guide
            <i
              class="i-ph-arrow-square-out -mt-[2px] ml-2 inline-block h-4 w-4"
            />
          </span>
        </AppNavDrawerItem>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.nav-ul {
  @apply flex w-full flex-col items-start justify-start space-y-4 px-3 text-neutral-300;
}
</style>
